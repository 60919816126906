import { combineReducers } from 'redux';
import headerReducer from './headerReducer';
import adviceReducer from './adviceReducer';
import userReducer from './user/userReducer';

const rootReducer = combineReducers({
  user: userReducer,
  header: headerReducer,
  advice: adviceReducer
});

export default rootReducer;