import { useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../../supabaseClient';
import { Anchor, Icon, Typography } from '../../design-system';
import { IRootState } from '../../store';
import { useSelector } from 'react-redux';
import { getTwoInitialLetters } from '../../utils/formatUtils';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

interface ISidebar {
  variant: 'APP' | 'TOOL';
  short?: boolean;
}

const Sidebar = ({ variant, short }: ISidebar) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>({} as User);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { role } = useSelector((state: IRootState) => state.advice);
  const isPartner = role === 'advisor';

  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      setUser(sessionData?.data.session?.user ?? null);
    };

    handleSession();
  }, []);

  const nameOrEmail = user?.user_metadata?.first_name
    ? [user?.user_metadata?.first_name, user?.user_metadata?.last_name]
        .filter((i) => i)
        .join(' ')
    : user?.email;

  const initials = getTwoInitialLetters(nameOrEmail || '');

  return (
    <>
      <div className={`bg-white shadow-lg h-spacing-72 justify-between items-center md:hidden flex px-spacing-16 fixed w-full ${showDropdown ? 'z-50' : 'z-10'}`}>
        <div className="flex flex-1">
          <a href={!!user ? '/' : 'https://tytle.io/'}>
            <Icon name={'Logo'} width={91} height={35} />
          </a>
        </div>
        <div className="flex relative">
          <button onClick={() => setShowDropdown(!showDropdown)}>
            <div className=" w-spacing-32 h-spacing-32 rounded-full flex justify-center items-center bg-indigo-500 uppercase">
              <Typography variant="BodyDefault500" color="white">
                {initials}
              </Typography>
            </div>
          </button>
          <div
            className={`flex gap-6 transition-all w-[80vw] !absolute top-[100%] mt-spacing-18 right-0 mr-[-16px] p-4 bg-white shadow-lg flex-col ${!showDropdown && 'hidden'}`}
          >
            {isPartner ? (
              <button
                onClick={() => {
                  setShowDropdown(false);
                  navigate(role === 'advisor' ? '/partner/info' : '/#');
                }}
                className="flex gap-3 cursor-pointer"
              >
                <Icon name="UserProfile" />
                <Typography variant={'BodyDefault400'}>
                  {t('sidebar.profile')}
                </Typography>
              </button>
            ) : (
              <></>
            )}
            <button
              onClick={() => {
                setShowDropdown(false);
                localStorage.clear();
                supabase.auth.signOut().catch(console.error);
                window.location.href = '/login';
              }}
              className="flex gap-3 cursor-pointer"
            >
              <Icon name="Leave" />
              <Typography variant={'BodyDefault400'}>
                {t('sidebar.logout')}
              </Typography>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`bg-black opacity-70 w-full h-full absolute top-0 right-0 z-40 ${!showDropdown && 'hidden'}`}
        onClick={() => setShowDropdown(false)}
      ></div>
      <div
        className={`!fixed flex flex-col msm:hidden md:flex ${short ? 'w-[100px]' : 'w-[270px]'} h-[100vh] py-spacing-16 px-spacing-24 justify-between`}
      >
        <a href={!!user ? '/' : 'https://tytle.io/'}>
          <Icon
            name={`${short ? 'ShortLogo' : 'Logo'}`}
            width={short ? 19 : 91}
            height={short ? 19 : 35}
          />
        </a>
        <div className={`flex flex-col ${short && 'hidden'}`}>
          <button
            onClick={() => {
              localStorage.clear();
              supabase.auth.signOut().catch(console.error);
              window.location.href = '/login';
            }}
            className="my-spacing-20 cursor-pointer"
          >
            <Icon name="Leave" />
          </button>
          <Anchor
            label={''}
            description={nameOrEmail}
            path={role === 'advisor' ? '/partner/info' : '/profile'}
            className="my-spacing-16 !p-0 !bg-base-gray25"
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
