const userprofile = {
    header: {
      title: 'Update your profile',
      text: 'It looks like you want to make changes to your profile. Here you can update basic information!',
    },
    profile_pic: 'Profile pic',
    first_name: 'First name',
    enter_first_name: 'Enter first name',
    invalid_first_name: 'Invalid first name',
    last_name: 'Last name',
    enter_last_name: 'Enter last name',
    invalid_last_name: 'Invalid last name',
    bio: 'Short professional bio',
    enter_bio: 'Short professional bio',
    welcome_message: 'Short initial message to send clients (optional)',
    invalid_bio: 'Invalid short professional bio',
    languages: 'Languages',
    add_more_languages: 'Add more languages',
    invalid_languages: 'Invalid languages',
    countries: 'Countries of expertise',
    invalid_countries: 'Invalid countries of expertise',
    thank_you: {
      title: 'Thank you, all done!',
      text:
        'Your profile has been successfully updated!',
      go_advices: 'Go to advices',
    },
    novuToggleText: 'I want to receive email notification about client activities'
  };
  
  export default userprofile;
  