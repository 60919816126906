/* eslint-disable import/no-anonymous-default-export */
import { configureStore } from '@reduxjs/toolkit';

import { IStateHeader } from './reducers/headerReducer';
import { IStateAdvice } from './reducers/adviceReducer';
import { IStateUser } from './reducers/user/userReducer';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers/rootReducer';


const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export interface IRootState {
  header: IStateHeader;
  advice: IStateAdvice;
  user: IStateUser;
}

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});